
import { defineComponent } from "vue";
import Bus from "@/utils/Bus";
import NotBuying from "@/components/QATutors/NotBuying.vue";
import axios from "axios";
import mixins from "@/mixins/index";
import RoundImageFlag from "@/components/image/RoundImageFlag.vue";

const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;
export default defineComponent({
  props: ["forumquestion", "langkind"],
  components: { RoundImageFlag },
  mixins: [mixins],
  data() {
    return {
      solveFlag: false, //解決loading控制按鈕
      loadingFlag: true, //抓取資料
      uploadFlag: false, //上傳檔案API是否完成
      uploadFile: false,
      photoFilename: "",
      // uploadfile: "", //上傳檔案預覽
      categorykey: 0,
      expand: true, //展開標籤
      questionData: "" as any,
      filterData: "" as any,
      noSolveData: "" as any,
      solvingData: "" as any,
      compeleteData: "" as any,
    };
  },

  //監聽props、利用語言別進行filter
  watch: {
    forumquestion: function () {
      this.filterData = this.forumquestion;
      // console.log("我是watch");
      // console.log(this.filterData);
    },
  },
  mounted() {
    // 問題總覽進畫面前先抓一次API;
    this.filterData = this.forumquestion;
    this.filterData.forEach((element: any) => {
      element.isExpand = false;
      element.uploadfile = "";
    });
    //有BUS事件產生重新抓取API並Render畫面
    Bus.on("newpost", () => {
      // console.log("接收到新增自動渲染畫面");
    });
  },
  methods: {
    // 收回訊息
    deleteQuestion(id: number) {
      // console.log(`delete Id: ${id}`);
      axios
        .delete(`${serverUrl}classhelp/${id}`, {
          headers: {
            Authorization: loginToken,
          },
        })
        .then((res) => {
          // console.log("delete Question");
          // console.log(res);
          if (res.status == 200) {
            this.filterData.forEach((ele: any, index: number) => {
              if (ele.id == id) {
                this.filterData.splice(index, 1);
                Bus.emit("openAlertModal", {
                  type: "success",
                  title: ["Done"],
                  content: "成功收回問題!",
                  btnText: "關閉",
                });
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 450) {
            Bus.emit("openAlertModal", {
              type: "error",
              title: ["Error!"],
              content: "此問題已被認領回答，請重新整理!",
              btnText: "關閉",
            });
          } else {
            Bus.emit("openAlertModal", {
              type: "error",
              title: ["Error!"],
              content: "收回問題失敗，請稍後重試!",
              btnText: "關閉",
            });
          }
        });
    },
    //區分未回覆、解決中、以解決
    async test(id: string, e: any, index: string) {
      //上傳檔案
      const file = e.target.files.item(0);
      let fileinfo = document.getElementById(id) as HTMLInputElement;
      let filerouter = fileinfo.value;
      let filename = filerouter.split(/(\\|\/)/g).pop();
      this.filterData[index].uploadfile = file.name;
      // 上傳檔案API
      let postforms = new FormData();
      postforms.append("type", "1");
      postforms.append("file", file);
      let config = {
        headers: {
          Authorization: loginToken,
          "Content-Type": "multipart/form-data",
        },
      };
      let res = await axios
        .post(`${serverUrl}common/file`, postforms, config)
        .then((res) => {
          if (res.status == 200) {
            this.uploadFlag = true;
            this.uploadFile = true;
            this.photoFilename = res.data.data.fileName;
          }
        })
        .catch((error) => {
          this.uploadFlag = true;
          this.uploadFile = false;
          Bus.emit("openAlertModal", {
            type: "warning",
            title: ["Warning !"],
            content: "檔案大小超過 1MB ，或是網路異常。",
            btnText: "關閉",
          });
        });
    },

    questionSolve(index: string) {
      this.$emit("statechange", index);
      this.solveFlag = true;
      let userid = this.filterData[index].id;
      axios
        .patch(
          `${serverUrl}classhelp/${userid}/resolve`,
          {
            isResolve: true,
          },
          {
            headers: {
              Authorization: loginToken,
            },
          }
        )
        .then((res) => {
          if (res.data.status == "success") {
            this.filterData[index].state = 2;
          } else if (res.status == 290) {
            // console.log("422失敗");
          }
          this.solveFlag = false;
        })
        .catch((error) => {
          // console.log("catch");
          console.log(error);
          this.solveFlag = false;
        });
    },
    changeExpand(index: string) {
      const result = this.filterData.find((element: any) => {
        return element.id == index;
      });
      result.isExpand = !result.isExpand;
    },
    //新增回應函式
    creatNewReply(index: number, idd: string) {
      this.filterData[index].uploadfile = "";
      let msg = null as any;
      let element = document.getElementById(idd) as HTMLInputElement;
      let b = Date.now();
      msg = element.value;
      // console.log(msg);
      if (msg != "") {
        let userid = this.filterData[index].id;
        axios
          .post(
            `${serverUrl}classhelp/${userid}/reply`,
            {
              content: msg as string,
              fileName: this.photoFilename,
            },
            {
              headers: {
                Authorization: loginToken,
              },
            }
          )
          .then((res) => {
            // console.log("回覆成功跑完");
            // console.log(res.data);
            if (res.data.status == "success") {
              this.filterData[index].reply.push({
                //前端新增回覆即時呈現
                replyContent: msg,
                replyFile: res.data.data.reply.replyFile,
                replyId: userid,
                replyPhoto: res.data.data.reply.replyPhoto,
                replyQuestioner: this.filterData[index].msgQuestion,
                replyRole: "Q",
                replyTime: b,
              });
              this.photoFilename = "";
              setTimeout(() => {
                this.scrollToBottom(index);
              }, 250);
              // console.log(res.data.data.reply.replyFile);
              // console.log("前端畫面成功回覆");
            }
          })
          .catch((error) => {
            console.error(`錯誤： ${error}`);
          });
        element.value = "";
      } else {
        Bus.emit("openAlertModal", {
          type: "warning",
          title: ["Warning!"],
          content: "請輸入回覆訊息",
          btnText: "關閉",
        });
        return;
      }
    },
    scrollToBottom(index: number) {
      const container = document.getElementsByClassName("smallModal")[index];
      if (container) {
        container.scrollTop = container.scrollHeight;
      }
    },
  },
});
