import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7b49d26a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"position":"relative","background-color":"#e5e5e5"} }
const _hoisted_2 = { class: "template1-container" }
const _hoisted_3 = {
  class: "row g-0",
  style: {"position":"inherit"}
}
const _hoisted_4 = { class: "d-none col-lg-2 d-lg-flex justify-content-center" }
const _hoisted_5 = { class: "col-12 col-lg-10 col-xxl-9" }
const _hoisted_6 = { class: "d-none col-xxl-1 d-xxl-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "left-column", {}, undefined, true)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _renderSlot(_ctx.$slots, "center-column", {}, undefined, true)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _renderSlot(_ctx.$slots, "right-column", {}, undefined, true)
        ])
      ])
    ])
  ]))
}