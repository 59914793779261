
import { defineComponent } from "vue";
import Bus from "@/utils/Bus";
import axios from "axios";
import mixins from "@/mixins/index";
const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;
export default defineComponent({
  mixins: [mixins],
  data() {
    return {
      tempMsg: "", //使用者輸入
      msg: "", //取得使用者輸入資料
      uploadFlag: false, //上傳檔案API是否完成
      photoFilename: "",
      uploadfile: "", //有無上傳檔案
      activeCategory: -1,
      chooselang: "",
      category: [] as Array<string>,
      alertMsg: [
        "系統不接受長篇翻譯或修改，若有問題請詢問客服人員。",
        "請勿輸入特殊符號，以免系統吃字。",
      ],
      postmsg: { lang: 123 },
    };
  },
  methods: {
    changeCategory(langIndex: number) {
      this.activeCategory = langIndex;
      this.chooselang = this.category[langIndex];
    },
    async test(e: any) {
      if (this.activeCategory === -1) {
        Bus.emit("openAlertModal", {
          type: "warning",
          title: ["Warning !"],
          content: "請先選擇語言分類。",
          btnText: "關閉",
        });
        return;
      }
      //上傳檔案
      const file = e.target.files.item(0);
      let fileinfo = document.getElementById("upload_img") as HTMLInputElement;
      let filerouter = fileinfo.value;
      let filename = filerouter.split(/(\\|\/)/g).pop();
      this.uploadfile = filename!;
      // 上傳檔案API

      let postforms = new FormData();
      postforms.append("type", "1");
      postforms.append("file", file);
      let config = {
        headers: {
          Authorization: loginToken,
          "Content-Type": "multipart/form-data",
        },
      };
      let res = await axios
        .post(`${serverUrl}common/file`, postforms, config)
        .then((res) => {
          if (res.status == 200) {
            this.uploadFlag = true;
            this.photoFilename = res.data.data.fileName;
          }
        })
        .catch((error) => {
          this.uploadFlag = true;
          this.uploadfile = "";
          let target = document.getElementById(
            "upload_img"
          )! as HTMLInputElement;
          target.value = "";
          Bus.emit("openAlertModal", {
            type: "warning",
            title: ["Warning !"],
            content: "檔案大小超過 1MB ，或是網路異常。",
            btnText: "關閉",
          });
        });
    },
    //發布貼文
    msgPost(id: string) {
      let element = document.getElementById(id) as HTMLInputElement;
      this.msg = element.value;
      if (element.value.length > 350) {
        Bus.emit("openAlertModal", {
          type: "error",
          title: ["Error!"],
          content: "輸入內容的字數太長，請不要超過 350 個字！",
          btnText: "關閉",
        });
        return;
      }
      let b = Date.now();
      if (this.msg != "") {
        const langMap = this.setLangCh3;
        axios
          .post(
            `${serverUrl}classhelp`,
            {
              content: this.msg as string,
              subject: langMap(this.chooselang),
              fileName: this.photoFilename,
            },
            {
              headers: {
                Authorization: loginToken,
              },
            }
          )
          .then((res) => {
            if (res.data.status == "success") {
              Bus.emit("newpost");
              Bus.emit("openAlertModal", {
                type: "success",
                title: ["Good job!"],
                content: "新增問題成功!",
                btnText: "關閉",
              });
            }
          })
          .catch((error) => {
            if (error.response.status == 455) {
              Bus.emit("openAlertModal", {
                type: "error",
                title: ["Error!"],
                content: "未購買此語言的問答家教",
                btnText: "關閉",
              });
            } else if (error.response.status == 422) {
              Bus.emit("openAlertModal", {
                type: "error",
                title: ["Error!"],
                content: "請輸入內容!",
                btnText: "關閉",
              });
              console.error(`錯誤： ${error}`);
            } else {
              Bus.emit("openAlertModal", {
                type: "error",
                title: ["Error!"],
                content: "新增問題失敗!",
                btnText: "關閉",
              });
              console.error(`錯誤： ${error}`);
            }
          });
        element.value = "";
      }
      this.uploadfile = "";
    },
  },
  mounted() {
    axios
      .get(`${serverUrl}classhelp/language`, {
        headers: {
          Authorization: loginToken,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          if (res.data.data.language.length > 0) {
            res.data.data.language.forEach((ele: { [key: string]: string }) => {
              this.category.push(this.setLangCh2(ele["code"]));
            });
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  },
});
