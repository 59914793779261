
import { defineComponent } from "vue";
import Bus from "@/utils/Bus";
import NotBuying from "@/components/QATutors/NotBuying.vue";
import Question from "@/components/QATutors/Question.vue";
import axios from "axios";
import mixins from "@/mixins/index";

const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;

export default defineComponent({
  props: ["langkind"],
  components: { NotBuying, Question },
  mixins: [mixins],
  data() {
    return {
      state: false,
      loadingFlag: true, //抓取資料
      categorykey: 0,
      questionData: "" as any,
      filterData: "" as any,
      noSolveData: "" as any,
      solvingData: "" as any,
      compeleteData: "" as any,
    };
  },

  //監聽props、利用語言別進行filter
  watch: {
    langkind: function () {
      this.filterData = "";
      if (this.langkind == "全部") {
        this.filterData = this.questionData;
      } else {
        let judgement = this.langkind;
        const langMap = this.setLangCh3;
        let testresult = this.questionData.filter(function (item: {
          language: string;
        }) {
          return item.language.match(langMap(judgement));
        });
        this.filterData = testresult;
        // console.log("結果");
        // console.log(this.filterData);
      }
      this.categorykey += 1;
      this.Classification(this.filterData);
    },
    questionData: function () {
      this.loadingFlag = false;
    },
  },
  mounted() {
    // 問題總覽進畫面前先抓一次API;
    this.addNewData();
    //有BUS事件產生重新抓取API並Render畫面
    Bus.on("newpost", () => {
      // console.log("接收到新增自動渲染畫面");
      this.addNewData();
    });
  },
  methods: {
    //區分未回覆、解決中、以解決
    Classification(data: any) {
      //區分為回覆
      let classifydata = data.filter(function (item: { state: string }) {
        return item.state.match("0");
      });
      this.noSolveData = classifydata;
      //區分解決中
      let solvingdata = data.filter(function (item: { state: string }) {
        return item.state.match("1");
      });
      this.solvingData = solvingdata;
      //區分已解決
      let donedata = data.filter(function (item: { state: string }) {
        return item.state.match("2");
      });
      // console.log(this.noSolveData)
      // console.log(this.solvingdata)
      // console.log(this.compeleteData)

      this.compeleteData = donedata;
      // console.log("未回覆");
      // console.log(this.noSolveData);
      // console.log("解決中");
      // console.log(this.solvingData);
      // console.log("以解決");
      // console.log(this.compeleteData);
    },

    changeExpand(index: string) {
      this.filterData[index].isExpand = !this.filterData[index].isExpand;
    },
    //call API取得資料
    addNewData() {
      axios
        .get(`${serverUrl}classhelp`, {
          headers: {
            Authorization: loginToken,
          },
        })
        .then((res) => {
          // console.log(loginToken);
          if (res.status == 200) {
            this.questionData = res.data.data.tutor;
            this.filterData = this.questionData as any;
            // console.log(this.questionData);
            // console.log("testing");
            // console.log(this.filterData);
            //新增變數控制Collapse按鈕、及上傳檔案預覽欄位
            this.filterData.forEach((element: any) => {
              element.isExpand = false;
              element.uploadfile = "";
            });
            this.Classification(this.filterData);
            this.state = true;
          }
        })
        .catch((error) => {
          console.error(`錯誤： ${error}`);
        });
    },
    //新增回應函式
  },
});
