
import { defineComponent } from "vue";
import CategoryCard from "@/components/QATutors/CategoryCard.vue";
import ContentCard from "@/components/QATutors/ContentCard.vue";
import Template1 from "@/views/templates/Template1.vue";

export default defineComponent({
  name: "Message",
  components: {
    Template1,
    CategoryCard,
    ContentCard,
  },
});
