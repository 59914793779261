
import { defineComponent } from "vue";
import axios from "axios";
import mixins from "@/mixins/index";
import Bus from "@/utils/Bus";
var serverUrl = process.env.VUE_APP_SERVER;
var loginToken = `Bearer ${localStorage.getItem("token")}` as string;
export default defineComponent({
  // props: ["activeCategory"],
  props: ["id"],
  mixins: [mixins],
  data() {
    return {
      tempMsg: "", //使用者輸入
      uploadFlag: false, //上傳檔案API是否完成
      photoFilename: "",
      apidata: "" as any,
      uploadfile: "",
      questionFlag: false,
      selectLang: true,
      activeCategory: -1,
      chooselang: "",
      category: [] as Array<string>,
      alertMsg: [
        "系統不接受長篇翻譯或修改，若有問題請詢問客服人員。",
        "請勿輸入特殊符號，以免系統吃字。",
      ],
    };
  },
  mounted() {
    axios
      .get(`${serverUrl}personal`, {
        headers: {
          Authorization: loginToken,
        },
      })
      .then((res) => {
        // console.log(loginToken);
        if (res.status == 200) {
          this.apidata = res.data.data.user.name;
          // console.log(this.apidata);
        }
      })
      .catch((error) => {
        console.error(`錯誤： ${error}`);
      });

    // 取得學生已購買語言別
    axios
      .get(`${serverUrl}classhelp/language`, {
        headers: {
          Authorization: loginToken,
        },
      })
      .then((res) => {
        // console.log(res);
        if (res.status == 200) {
          // console.log(res.data.data.language);
          if (res.data.data.language.length > 0) {
            res.data.data.language.forEach((ele: { [key: string]: string }) => {
              this.category.push(this.setLangCh2(ele["code"]));
            });
            // console.log(this.category);
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
  },
  methods: {
    openModal() {
      this.$emit("openSmallAskingModal");
    },
    closeModal() {
      this.$emit("closeSmallAskingModal");
    },
    hideShortcutkey() {
      const taget = document.getElementById("side-tools")! as HTMLElement;
      taget.style.display = "none";
      // console.log("按鈕消失");
    },
    showShortcutkey() {
      const taget = document.getElementById("side-tools")! as HTMLElement;
      taget.style.display = "block";
      // console.log("按鈕回復");
    },
    changeCategory(langIndex: number) {
      this.selectLang = false;
      this.questionFlag = true;
      this.activeCategory = langIndex;
      this.chooselang = this.category[langIndex];
    },
    async test(e: any) {
      if (this.activeCategory === -1) {
        Bus.emit("openAlertModal", {
          type: "warning",
          title: ["Warning !"],
          content: "請先選擇語言分類。",
          btnText: "關閉",
        });
        return;
      }
      const file = e.target.files.item(0);
      let fileinfo = document.getElementById("upload_file") as HTMLInputElement;
      // console.log("files", file);
      // console.log(fileinfo);
      let filerouter = fileinfo.value;
      let filename = filerouter.split(/(\\|\/)/g).pop();
      // console.log(filename);
      this.uploadfile = filename!;
      // 上傳檔案API

      let postforms = new FormData();
      postforms.append("type", "1");
      postforms.append("file", file);
      let config = {
        headers: {
          Authorization: loginToken,
          "Content-Type": "multipart/form-data",
        },
      };

      let res = await axios
        .post(`${serverUrl}common/file`, postforms, config)
        .then((res) => {
          if (res.status == 200) {
            this.uploadFlag = true;
            this.photoFilename = res.data.data.fileName;
          }
        })
        .catch((error) => {
          this.uploadFlag = true;
          this.uploadfile = "";
          let target = document.getElementById(
            "upload_file"
          )! as HTMLInputElement;
          target.value = "";
          Bus.emit("openAlertModal", {
            type: "warning",
            title: ["Warning !"],
            content: "檔案大小超過 1MB ，或是網路異常。",
            btnText: "關閉",
          });
        });
    },
    msgPost(id: string) {
      if (this.activeCategory === -1) {
        Bus.emit("openAlertModal", {
          type: "warning",
          title: ["Warning !"],
          content: "請選擇語言分類",
          btnText: "關閉",
        });
        return;
      }
      let element = document.getElementById(id) as HTMLInputElement;
      let msg = element.value;
      if (element.value.length > 350) {
        Bus.emit("openAlertModal", {
          type: "error",
          title: ["Error!"],
          content: "輸入內容的字數太長，請不要超過 350 個字！",
          btnText: "關閉",
        });
        return;
      }
      let b = Date.now();
      this.uploadfile = "";
      if (msg != "") {
        const langMap = this.setLangCh3;
        axios
          .post(
            `${serverUrl}classhelp`,
            {
              content: msg as string,
              subject: langMap(this.chooselang),
              fileName: this.photoFilename,
            },
            {
              headers: {
                Authorization: loginToken,
              },
            }
          )
          .then((res) => {
            // console.log("成功跑完");
            // console.log(res.data);
            Bus.emit("refreshPage", "sent emit");
            // console.log("成功送出emit");
            if (res.data.status == "success") {
              Bus.emit("openAlertModal", {
                type: "success",
                title: ["Good job!"],
                content: "新增問題成功!",
                btnText: "關閉",
              });
              Bus.emit("newpost");
              element.value = "";
              this.closeModal();
              this.selectLang = true;
              // console.log(res.data);
            }
          })
          .catch((error) => {
            if (error.response.status == 455) {
              Bus.emit("openAlertModal", {
                type: "error",
                title: ["Error!"],
                content: "未購買此語言的問答家教",
                btnText: "關閉",
              });
            } else {
              Bus.emit("openAlertModal", {
                type: "error",
                title: ["Error!"],
                content: "新增問題失敗!",
                btnText: "關閉",
              });
              console.error(`錯誤： ${error}`);
            }
          });
      }
    },
  },
});
