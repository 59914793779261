
import { defineComponent } from "vue";
import { Modal } from "bootstrap";
import axios from "axios";
import mixins from "@/mixins/index";
import Forum from "@/components/QATutors/Forum.vue";
import Asking from "@/components/QATutors/Asking.vue";
import SmallAsking from "@/components/QATutors/SmallAsking.vue";

const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;

export default defineComponent({
  props: ["contentquestion"],
  components: {
    Forum,
    Asking,
    SmallAsking,
  },
  mixins: [mixins],
  data() {
    return {
      activeCategory: 0,
      category: ["全部"],
      chooselang: "",
      bannerBg: {
        backgroundImage: "url(/static/img/qaTutor/banner-plain.png)",
      },
      smallAskingModal: null as any,
      smallAskingModalId: "smallAskingModal",
    };
  },
  methods: {
    changeCategory(langIndex: number) {
      this.activeCategory = langIndex;
      this.chooselang = this.category[langIndex];
    },
    openSmallAskingModal() {
      this.smallAskingModal.show();
    },
    closeSmallAskingModal() {
      this.smallAskingModal.hide();
    },
  },
  mounted() {
    axios
      .get(`${serverUrl}common/subject`, {
        headers: {
          Authorization: loginToken,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          for (let key in res.data.data.subject) {
            this.category.push(this.setLangCh2(key));
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });
    this.smallAskingModal = new Modal(
      document.getElementById(`${this.smallAskingModalId}`)! as HTMLElement
    );
  },
});
